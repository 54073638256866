import React from 'react';

import './App.css';
import {Router} from "./plugins/router";

function App() {
    return (
        <div className="App">

            <Router/>

        </div>
    );
}

export default App;


// contact c by email: ceresajones214@outlook.com
